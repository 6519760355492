import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["currentlyTakingTrue", "currentlyTakingFalse"];

  disableLastQuestion(e) {
    this.currentlyTakingFalseTarget.checked = true;
    this.currentlyTakingFalseTarget.disabled = true;
    this.currentlyTakingTrueTarget.disabled = true;
  }

  enableLastQuestion(e) {
    this.currentlyTakingFalseTarget.checked = null;
    this.currentlyTakingFalseTarget.disabled = false;
    this.currentlyTakingTrueTarget.disabled = false;
  }

  submitBaseline() {
    this.currentlyTakingFalseTarget.disabled = false;
    this.currentlyTakingTrueTarget.disabled = false;
  }
}
